import React from 'react'
import styles from './style.module.scss'

export const HamburgerMenu = ({ onClick }) => {
	return (
		<div className={`${styles.wrapper} hamburger-menu`} onClick={onClick}>
			<div className={styles.menu} />
		</div>
	)
}

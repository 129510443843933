import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { collegesConfig } from '@Configs/collegeConfig'
import { attractionOptions, booleanOptions, communicationOptions, heardFrom } from '@Root/configs'

export const ConnectionToUs = ({ onChange, data, isMobile }) => {
	const { first_hear_about_trinity, attracts_you_to_trinity, communicate_with_you_in_the_future, applied_before } = data

	return (
		<div className={styles.section}>
			<SectionTitle>8. Your connection to us</SectionTitle>
			<div className={styles.fields}>
				<Field
					label={`How did you first hear about ${collegesConfig()}?`}
					options={heardFrom}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={first_hear_about_trinity}
					onChange={onChange('first_hear_about_trinity')}
					isInline
					fieldClass={'single'}
				/>{' '}
				<Field
					label={`What is it that attracts you to ${collegesConfig()}?`}
					options={attractionOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={attracts_you_to_trinity}
					onChange={onChange('attracts_you_to_trinity')}
					isInline
					fieldClass={'single'}
				/>{' '}
				<Field
					label={'How would you like us to communicate with you in the future?'}
					options={communicationOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '96%' : '150px' }}
					value={communicate_with_you_in_the_future}
					onChange={onChange('communicate_with_you_in_the_future')}
					isInline
					fieldClass={'single'}
				/>
				<Field
					label={'Have you applied before?'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '96%' : '150px' }}
					value={applied_before?.value ? 1 : 2}
					onChange={(value) => onChange('applied_before')({ ...data.applied_before, value: value === 1 })}
					isInline
					fieldClass={'single'}
				/>
				<Field
					label={'If yes, provide details here:'}
					field={'textArea'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '96%', height: '90px', marginTop: '4px' }}
					value={applied_before?.note_details}
					onChange={(value) => onChange('applied_before')({ ...data.applied_before, note_details: value })}
					isInline
					maxLength={255}
					fieldClass={'single'}
				/>
			</div>
		</div>
	)
}

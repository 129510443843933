import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { booleanOptions, churchOfEnglandOptions, sourceFundingOptions, timesOptions } from '@Root/configs'

export const Programme = ({ onChange, data, errors = {}, isMobile, options }) => {
	const {
		church_of_england,
		study_program,
		full_time,
		context_based_training,
		source_of_funding,
		launching_educational,
		proposed_yeas_study,
		outcome_pioneer_minister,
	} = data

	return (
		<div className={styles.section}>
			<SectionTitle id={"your_programme"}>3. Your programme</SectionTitle>
			<div className={styles.fields}>
				<Field
					label={'Are you intending to pursue ordination in the Church of England or independent theological study?'}
					options={churchOfEnglandOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={church_of_england}
					onChange={onChange('church_of_england')}
					isInline
					fieldClass={'single'}
					isRequired
					error={errors.church_of_england}
				/>
				<div id={errors.study_program ? 'scrollHere' : null}>
					<Field
						label={'Which study programme are you interested in pursuing?'}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '240px', borderColor: errors.study_program ? 'red' : 'transparent' }}
						value={study_program}
						onChange={onChange('study_program')}
						options={options.programmes}
						fieldClass={'single'}
						overrideStrings={{
							selectSomeItems: 'Select some programmes...',
						}}
						disableSearch
						field={'multiSelect'}
					/>
				</div>
				{study_program.length !== 0 && (
					<div data-test-id='multi-select-option'>
						<div className={styles.selected_programme_title}>Selected programmes: </div>
						{options?.programmes?.map((option, i) => {
							return (
								study_program.some((selected) => selected === option.value) && (
									<div key={i} className={styles.selected_programme_item}>
										{option.label}
									</div>
								)
							)
						})}
					</div>
				)}
				<Field
					label={'Are you intending to to study full time or part time?'}
					options={timesOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={full_time}
					onChange={onChange('full_time')}
					isInline
					fieldClass={'single'}
				/>{' '}
				<Field
					label={'Are you considering Context-based training (ie full time, non-residential)?'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={context_based_training ? 1 : 2}
					onChange={(value) => onChange('context_based_training')(value === 1)}
					isInline
					fieldClass={'single'}
				/>
				<Field
					label={'What is the most likely source of funding for your study?'}
					options={sourceFundingOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={source_of_funding}
					onChange={onChange('source_of_funding')}
					isInline
					fieldClass={'single'}
				/>
				<Field
					label={'If you would like to defer your entry to SMC, please state month and year of your prefered date of entry'}
					field={'textField'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '556px' }}
					value={launching_educational}
					onChange={onChange('launching_educational')}
					maxLength={100}
					fieldClass={'single'}
				/>
				<Field
					label={'Proposed year of study'}
					options={options.proposed_yeas_study}
					field={'select'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					optionsStyle={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					value={proposed_yeas_study}
					onChange={onChange('proposed_yeas_study')}
					isInline
					fieldClass={'long'}
				/>{' '}
				<p>
					NB: <span style={{ color: '#9B9B9B' }}>If you're an independent student please disregard the following questions</span>
				</p>
				<Field
					label={'Are you going through selection with the intention of being an Ordained Pioneer Minister (OPM)?'}
					options={[...booleanOptions, { value: 3, label: 'Unknown' }]}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={outcome_pioneer_minister}
					onChange={onChange('outcome_pioneer_minister')}
					isInline
					fieldClass={'single'}
				/>
			</div>
		</div>
	)
}

import React from 'react'
import styles from './style.module.scss'
import { navigationPages } from '@Configs/navigationPages'
import { Container } from '@Root/Components/Container'
import { Logo } from '@Root/assets/icons'
import { HamburgerMenu } from './Hamburger'
import { useToggle } from '@Root/hooks'
import { MobileNavigation } from './MobileNavigation'
import { Button } from './Button'

export const Header = () => {
	const [isOpen, toggleOpen] = useToggle(false)

	return (
		<header className={`${styles.header}  ${isOpen ? styles.open : ''}`}>
			<div className={`${styles.generalNavigation}`}>
				<Container>
					<a href={process.env.REACT_APP_DOMAIN_NAME}>
						{' '}
						<img src={Logo} alt='logo' className={styles.logo} />
					</a>
					<nav className={styles.generalNavigationWrapper}>
						<a href={`${process.env.REACT_APP_DOMAIN_NAME}/about-us/news`} target='_blank' rel='noreferrer'>
							News
						</a>
						<a href={`${process.env.REACT_APP_DOMAIN_NAME}/about-us/events`} target='_blank' rel='noreferrer'>
							Events
						</a>
						<a href={`${process.env.REACT_APP_DOMAIN_NAME}/about-us/staff-team`} target='_blank' rel='noreferrer'>
							Staff Team
						</a>
					</nav>
					<HamburgerMenu onClick={toggleOpen} />
				</Container>
			</div>
			<div className={styles.mobileNavigation}>
				<MobileNavigation />
			</div>
			<nav className={styles.navigationWrapper}>
				<Container>
					<div className={styles.navigation}>
						{navigationPages.map(({ link, subcategories, title }) => {
							return (
								<div className={styles.item} key={link}>
									<a href={`${process.env.REACT_APP_DOMAIN_NAME}/${link}`} className={styles.title} target='_blank' rel='noreferrer'>
										{title}
									</a>
									<div className={styles.menuWrapper}>
										<ul className={styles.menu}>
											{subcategories.map(({ title, link }) => {
												return (
													<li className={styles.menuItem} key={link}>
														<a href={`${process.env.REACT_APP_DOMAIN_NAME}/${link}`} target='_blank' rel='noreferrer'>
															{title}
														</a>
													</li>
												)
											})}
										</ul>
									</div>
								</div>
							)
						})}
						<Button to={`${process.env.REACT_APP_DOMAIN_NAME}/apply`}>Apply</Button>
					</div>
				</Container>
			</nav>
		</header>
	)
}

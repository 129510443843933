import React, { Component } from 'react'
import './homePage.scss'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import studentFormSubmission from '../StudentFormSubmission/studentFormSubmission'
import LoginToContinue from '../LoginToContinue/loginToContinue'
import { ApplicationPage } from '@Root/pages'
import { Footer } from '@Root/Components/Footer'
import { Header } from '@Root/Components/Header'

class homePage extends Component {
	state = {
		isMobile: false,
		menuIsShown: true,
		openedSubmenu: null,
	}

	containerRef = React.createRef()

	collegeTitles = (collegeId) => {
		return {
			trinitycollegebristol: ' Trinity College, Bristol - Taught Programmes Application Form',
			theofedcam: 'Cambridge Federation',
			stmellitus: 'St Mellitus College',
		}[collegeId]
	}

	setIsMobile = () => {
		this.setState({
			isMobile: this.containerRef.current.offsetWidth < 615,
		})
	}

	componentDidMount() {
		this.setIsMobile()
		window.addEventListener('resize', this.setIsMobile)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isMobile === true && this.state.isMobile === false) {
			this.setState({ menuIsShown: true, openedSubmenu: null })
		}
		if (prevState.isMobile === false && this.state.isMobile === true) {
			this.setState({ menuIsShown: false, openedSubmenu: null })
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setIsMobile)
	}

	render() {
		const { isMobile } = this.state

		const collegeId = this.props.location.pathname.split('/')[1]

		return (
			<div ref={this.containerRef}>
				<Header />
				<Switch>
					<Redirect exact from={`/home`} to={`/home/formSubmission`} />
					<Route exact path={`/home/formSubmission`} component={studentFormSubmission} />
					<Route exact path={`/home/formSubmission/studentForm`} render={() => <ApplicationPage isMobile={isMobile} />} />
					<Route exact path={`/home/continue/login`} render={() => <LoginToContinue isMobile={isMobile} />} />
					<Route exact path={`/home/continue/form`} component={() => <ApplicationPage isMobile={isMobile} />} />
				</Switch>
				<Footer />
			</div>
		)
	}
}

export default withRouter(homePage)

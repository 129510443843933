export const navigationPages = [
	{
		title: 'Study with us',
		link: 'study-with-us',
		subcategories: [
			{ title: 'Our centres', link: 'study-with-us/our-centres' },
			{ title: 'Come & Meet us', link: 'come-and-meet-us' },
			{ title: 'Community & Support', link: 'study-with-us/support' },
			{ title: 'Parents in training', link: 'parents-in-training' },
			{ title: 'Application process', link: 'study-with-us/application-process' },
			{ title: 'Entry requirements', link: 'study-with-us/entry-requirements' },
			{ title: 'Fees & Validation', link: 'study-with-us/fees' },
			{ title: 'FAQ', link: 'study-with-us/faqs' },
			{ title: 'View our prospectus', link: 'ourprospectus' },
		],
	},
	{
		title: 'Programmes',
		link: 'programmes',
		subcategories: [
			{ title: 'Our centres', link: 'study-with-us/our-centres' },
			{ title: 'Come & Meet us', link: 'come-and-meet-us' },
			{ title: 'Community & Support', link: 'study-with-us/support' },
			{ title: 'Parents in training', link: 'parents-in-training' },
			{ title: 'Application process', link: 'study-with-us/application-process' },
			{ title: 'Entry requirements', link: 'study-with-us/entry-requirements' },
			{ title: 'Fees & Validation', link: 'study-with-us/fees' },
			{ title: 'FAQ', link: 'study-with-us/faqs' },
			{ title: 'View our prospectus', link: 'ourprospectus' },
		],
	},
	{
		title: 'Student & Partners',
		link: 'students-partners',
		subcategories: [
			{ title: 'Our centres', link: 'study-with-us/our-centres' },
			{ title: 'Come & Meet us', link: 'come-and-meet-us' },
			{ title: 'Community & Support', link: 'study-with-us/support' },
			{ title: 'Parents in training', link: 'parents-in-training' },
			{ title: 'Application process', link: 'study-with-us/application-process' },
			{ title: 'Entry requirements', link: 'study-with-us/entry-requirements' },
			{ title: 'Fees & Validation', link: 'study-with-us/fees' },
			{ title: 'FAQ', link: 'study-with-us/faqs' },
			{ title: 'View our prospectus', link: 'ourprospectus' },
		],
	},
	{
		title: 'About us',
		link: 'about-us',
		subcategories: [
			{ title: 'Our centres', link: 'study-with-us/our-centres' },
			{ title: 'Come & Meet us', link: 'come-and-meet-us' },
			{ title: 'Community & Support', link: 'study-with-us/support' },
			{ title: 'Parents in training', link: 'parents-in-training' },
			{ title: 'Application process', link: 'study-with-us/application-process' },
			{ title: 'Entry requirements', link: 'study-with-us/entry-requirements' },
			{ title: 'Fees & Validation', link: 'study-with-us/fees' },
			{ title: 'FAQ', link: 'study-with-us/faqs' },
			{ title: 'View our prospectus', link: 'ourprospectus' },
		],
	},
]

import React from 'react'

export const EmailIcon = () => {
	return (
		<svg
			id='Capa_1'
			enableBackground='new 0 0 512 512'
			height='512'
			viewBox='0 0 512 512'
			width='512'
			xmlns='http://www.w3.org/2000/svg'
			className={'email-icon'}
		>
			<g>
				<path d='m0 62v388h512v-388zm256 223.309-203.438-193.309h406.876zm-81.746-36.294-144.254 148.938v-286.008zm21.748 20.666 59.998 57.01 59.998-57.011 145.592 150.32h-411.18zm141.745-20.666 144.253-137.07v286.008z' />
			</g>
		</svg>
	)
}

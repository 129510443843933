import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'

export const UserStory = ({ onChange, data, isMobile }) => {
	const { story } = data
	return (
		<div className={styles.section}>
			<SectionTitle>6. Your story</SectionTitle>
			<div className={`${styles.fields} ${styles.story}`}>
				<p>
					Please use this space, within 5000 characters, to tell us more about yourself – perhaps something of your spiritual journey, your interests and
					passions or your current ministry and mission involvements. If you are pursuing ordination, it would also be useful for us to hear about the kind of
					ministry you hope to develop in the future (e.g. parish, chaplaincy, theological education, etc.) If you are an independent applicant then it would be
					useful for us to know what your plans for your career after studying with us might be.
				</p>
				<Field
					hideLabel
					label={''}
					field={'textArea'}
					//Note: 20px in calc - right and left padding of textarea
					style={{ height: 293, width: isMobile ? 'calc(100% - 20px)' : '530px' }}
					value={story}
					onChange={onChange('story')}
					fieldClass={'long'}
					maxLength={5000}
				/>
			</div>
		</div>
	)
}

import React from 'react'
import styles from './style.module.scss'
import {
	ChurchOfEnglandLogo,
	DurhamUniversityLogo,
	EmailIcon,
	FacebookIcon,
	InstagramIcon,
	MiddlesexCollegeLogo,
	StPaulusCollegeLogo,
	TwitterIcon,
} from '@Root/assets/icons'
import { Container } from '@Root/Components/Container'

export const Footer = () => {
	return (
		<div className={styles.footer}>
			<div className={styles.footerInformation}>
				<Container>
					<div className={styles.generalInformation}>
						<div className={styles.grid}>
							<ul className={styles.links}>
								<li className={styles.link}>
									<a href='mailto:info@stmellitus.ac.uk <info@stmellitus.ac.uk>' target='_self'>
										<EmailIcon />
									</a>{' '}
								</li>{' '}
								<li className={styles.link}>
									<a href='https://www.facebook.com/StMellitusCollege/?ref=bookmarks' target='_blank' rel='noreferrer'>
										<FacebookIcon />
									</a>{' '}
								</li>{' '}
								<li className={styles.link}>
									<a href='https://twitter.com/stmellitus' target='_blank' rel='noreferrer'>
										<TwitterIcon />
									</a>{' '}
								</li>{' '}
								<li className={styles.link}>
									<a href='https://www.instagram.com/StMellitus' target='_blank' rel='noreferrer'>
										<InstagramIcon />
									</a>{' '}
								</li>
							</ul>
							<ul className={`${styles.links} ${styles.flexWrap}`}>
								<li className={styles.link}>
									<a href={`${process.env.DOMAIN_NAME}/about-us`}>About us</a>
								</li>
								<li className={styles.link}>
									<a href={`${process.env.DOMAIN_NAME}/privacy-policy`}>Privacy Policy</a>
								</li>
								<li className={styles.link}>
									<a href={`${process.env.DOMAIN_NAME}/about-us/contact-us`}>Contact us</a>
								</li>{' '}
								<li className={styles.link}>
									<a href={`${process.env.DOMAIN_NAME}/give`}>Give</a>
								</li>{' '}
								<li className={styles.link}>
									<a href={`${process.env.DOMAIN_NAME}/about-us/job-opportunities`}>Job Opportunities</a>
								</li>
							</ul>
						</div>
						<div className={styles.information}>
							<i className={styles.contactUs}>
								Is there something wrong with this page? Let us know{' '}
								<a className={styles.highlighted} href='mailto:info@stmellitus.ac.uk' target='_self'>
									here
								</a>
							</i>
							<div className={styles.content}>
								<p>
									<strong>Safeguarding </strong> &nbsp;– St Mellitus College is committed to the safeguarding, care and nurture of everyone within our
									community.
								</p>
								<p>
									You can contact our Safeguarding Officer at{' '}
									<a className={styles.highlighted} href='mailto:safeguarding@stmellitus.ac.uk'>
										safeguarding@stmellitus.ac.uk
									</a>
								</p>
								<p>
									For more information about safeguarding at St Mellitus College{' '}
									<a
										className={styles.highlighted}
										href='https://www.stmellitus.ac.uk/about-us/policies-reports/safeguarding-and-prevent'
										target='_blank'
										rel='noreferrer'
									>
										{' '}
										click here
									</a>
								</p>
								<p>
									<strong>St Mellitus College Trust</strong> – A Company Limited by Guarantee; Registered in England (Number: 4546328);
								</p>
								<p>A Registered Charity (Number: 1094157); Registered Office: 24 Collingham Road, London, SW5 0LX.</p>
							</div>
						</div>
					</div>
					{/*<div className={styles.supportFrom}>123</div>*/}
				</Container>
			</div>
			<div className={styles.supportBy}>
				<Container>
					<div className={styles.items}>
						<a href='https://sptc.htb.org/' target='_blank' rel='noreferrer' className={styles.item}>
							<img src={StPaulusCollegeLogo} alt='St Paul`s' />
						</a>
						<a href='https://www.dur.ac.uk/' target='_blank' rel='noreferrer' className={styles.item}>
							<img src={DurhamUniversityLogo} alt='Durham University' />
						</a>
						<a href='https://www.mdx.ac.uk/' target='_blank' rel='noreferrer' className={styles.item}>
							<img src={MiddlesexCollegeLogo} alt='Middlesex University' />
						</a>
						<a href='https://www.churchofengland.org/' target='_blank' rel='noreferrer' className={styles.item}>
							<img src={ChurchOfEnglandLogo} alt='The Church of England' />
						</a>
					</div>
				</Container>
			</div>
		</div>
	)
}

import React from "react";
import  "./FormTable.scss";
import PropTypes from "prop-types";

const FormTable = ({ colomns, rows, onTxtAreaInput, onAddBtn }) => {
    return (
        <div className="FormTable">
            <div className="tableWrapper">
            <table>
                <thead>
                <tr>
                    {
                        colomns.map((colomn, key) => {
                            return <th key={key}>{colomn}</th>
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    rows.map((fields, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                {
                                    Object.keys(fields).map((field, i) => {
                                        return(
                                            (field !== "id") && (
                                        <td key={i}>
                                            <textarea value={fields[field]}
                                                       onChange={(e) => {onTxtAreaInput(rowIndex,field, e.target.value,rows)}}>{}
                                            </textarea>
                                        </td>
                                            )
                                        )


                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            </div>
            <div className="add-btn" onClick={() => {onAddBtn(rows)}}>Add field</div>
        </div>
    );
};

FormTable.propTypes = {
    colomns: PropTypes.array,
    rows: PropTypes.array,
    onTxtAreaInput: PropTypes.func,
    onAddMtn: PropTypes.func
};

export default FormTable;
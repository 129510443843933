import React from 'react'
import Loader from 'react-loader-spinner'
import styles from './style.module.scss'
export const Preloader = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.loader}>
				<Loader type='BallTriangle' color='#5d272f' height={100} width={100} className='pre-loader-icon' />
			</div>
		</div>
	)
}

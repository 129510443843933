import React, { PureComponent } from 'react'
import classes from './DatePicker.module.scss'
import PropTypes from 'prop-types'
import { delay } from 'redux-saga'
import { all } from '@Root/helpers'
import onClickOutside from 'react-onclickoutside'
import triangle from '../../assets/icons/triangle.png'
import { InputError } from '@Root/HOCs'
import DatePickerPopup from './DatePickerPopup/DatePickerPopup.jsx'

class DatePicker extends PureComponent {
	state = { popupIsShown: false, error: null, close: false }

	handleClickOutside = () => {
		this.setState({ popupIsShown: false })
	}

	showError = async (error) => {
		this.setState({ error })
		await delay(3000)
		this.setState({ error: null })
	}

	onPickHandler = (value) => {
		this.props.changeHandler(value)
		this.setState({ popupIsShown: false })
	}

	componentDidUpdate(prevProps) {
		if (this.props.error !== prevProps.error) {
			this.props.error && this.showError(this.props.error)
		}
	}

	render() {
		const { popupIsShown } = this.state
		const { value, isDisabled, inputClassNames, inputStyle, error, width } = this.props
		const { onPickHandler } = this

		return (
			<div className={classes.DatePicker} data-test-id='test-data-picker'>
				<InputError style={{ width }} error={error}>
					<div
						className={`${classes.input} ${isDisabled ? classes.disabled : null} 
                        ${inputClassNames ? inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, '') : ''}`}
						style={inputStyle}
						onClick={() =>
							all(
								() => !isDisabled && this.setState({ popupIsShown: !popupIsShown }),
								() => this.setState({ error: null })
							)
						}
					>
						{value ? (
							<div className={classes.value} data-test-id='data-picker-value'>
								{value}
							</div>
						) : (
							<div className={`${classes.value} ${classes.empty}`}>Select date</div>
						)}
						{!isDisabled && (
							<div className={classes.icon}>
								<img style={popupIsShown ? { transform: 'rotate(180deg)' } : null} src={triangle} alt='' />
							</div>
						)}
					</div>
				</InputError>
				{popupIsShown && (
					<div className={`${classes.popup}`} onChange={() => {}}>
						<DatePickerPopup value={value} changeHandler={onPickHandler} />
					</div>
				)}
			</div>
		)
	}
}

DatePicker.propTypes = {
	value: PropTypes.string,
	changeHandler: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
	inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent', 'borderlessWhenDisabled'])),
	inputStyle: PropTypes.object,
	width: PropTypes.string,
}

export default onClickOutside(DatePicker)

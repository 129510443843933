import React from 'react'
import './DatePickerPopup.scss'
import PropTypes from 'prop-types'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar } from 'react-date-range'
import moment from 'moment'

const DatePickerPopup = ({ value, changeHandler }) => {
	return (
		<div className='DatePickerPopup'>
			<Calendar
				date={value ? new Date(moment(value, 'DD-MM-YYYY')) : new Date()}
				onChange={(value) => changeHandler(moment(value).format('DD-MM-YYYY'))}
				showPreview={false}
				className={'calendar-date'}
			/>
		</div>
	)
}

DatePickerPopup.propTypes = {
	value: PropTypes.string,
	changeHandler: PropTypes.func.isRequired,
}

export default DatePickerPopup

import React from 'react'
import styles from './style.module.scss'

export const Button = ({ to, children }) => {
	return (
		<a className={`${styles.button} button`} href={to}>
			{children}
		</a>
	)
}

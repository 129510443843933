export const churchOfEnglandOptions = [
	{ value: 1, label: 'Ordinand' },
	{ value: 2, label: 'Independent' },
]

export const timesOptions = [
	{ value: 1, label: 'FT' },
	{ value: 2, label: 'PT' },
]

export const booleanOptions = [
	{ value: 1, label: 'Yes' },
	{ value: 2, label: 'No' },
]

export const heardFrom = [
	{ value: 1, label: 'Former student' },
	{ value: 2, label: 'Current student' },
	{ value: 3, label: 'Facebook' },
	{ value: 4, label: 'Twitter' },
	{ value: 5, label: 'Google' },
	{ value: 6, label: 'Church' },
]

export const attractionOptions = [
	{ value: 1, label: 'Academic programme' },
	{ value: 2, label: 'Vision' },
	{ value: 3, label: 'Faculty' },
	{ value: 4, label: 'Community' },
	{ value: 5, label: 'Worship style' },
	{ value: 6, label: 'Other' },
	{ value: 7, label: 'Location' },
	{ value: 8, label: 'Theological position of the college' },
]

export const communicationOptions = [
	{ value: 1, label: 'Post' },
	{ value: 2, label: 'Email' },
	{ value: 3, label: 'Phone' },
	{ value: 4, label: 'Social media' },
]

export const educationQualificationOptions = ['Institution', 'Subject', 'Award', 'Result(s)', 'Date']

export const professionalQualificationsOptions = ['Institution', 'Subject', 'Award', 'Result(s)', 'Date']

export const recentEmploymentOptions = ['Employer', 'Role', 'Dates from/to', 'FT/PT']

export const britishVisaOptions = [
	{ value: 1, label: 'Not needed' },
	{ value: 2, label: 'Granted' },
	{ value: 3, label: 'Required' },
	{ value: 4, label: 'indefinite leave to remain' },
]

export const outcomeBapOptions = [
	{ value: 1, label: 'Not recommended' },
	{ value: 2, label: 'Not yet known' },
	{ value: 3, label: 'Recommended' },
	{ value: 4, label: 'No BAP date fixed at this stage' },
]

export const sourceFundingOptions = [
	{ value: 1, label: 'Personal finance}' },
	{ value: 2, label: 'Supporting church' },
	{ value: 3, label: 'Student loan' },
	{ value: 4, label: 'CofE funding' },
	{ value: 5, label: 'Other' },
]

export const maritalCircumstances = [
	{ value: 1, label: 'Single' },
	{ value: 2, label: 'Married' },
	{ value: 3, label: 'Widowed' },
	{ value: 4, label: 'Separated' },
	{ value: 5, label: 'Divorced' },
	{ value: 6, label: 'Other' },
]

export const fundYourCourseStudyOptions = [
	{ value: 1, label: 'Self/family' },
	{ value: 2, label: 'Student Finance Direct (student loan)' },
	{ value: 3, label: 'Diocese/church/sponsor' },
	{ value: 4, label: 'Other' },
]

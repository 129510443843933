import React, { useState } from 'react'
import styles from './style.module.scss'
import { navigationPages } from '@Configs/navigationPages'
import { ArrowIcon } from '@Root/assets/icons'
import { Button } from '@Root/Components/Header/Button'

export const MobileNavigation = () => {
	const [openedCategoryId, setOpenedCategoryId] = useState('')

	const onChangeOpenedCategory = (id) => {
		setOpenedCategoryId((prevState) => (prevState === id ? null : id))
	}
	return (
		<nav className={styles.navigation}>
			{navigationPages.map(({ title, link, subcategories }) => {
				return (
					<div className={`${styles.item} ${openedCategoryId === title ? styles.open : ''}`} key={link}>
						<div className={styles.title}>
							<a href={`${process.env.REACT_APP_DOMAIN_NAME}/${link}`}>{title}</a>
							<img src={ArrowIcon} alt='arrow' className={styles.arrow} onClick={() => onChangeOpenedCategory(title)} />
						</div>
						<ul className={styles.subcategories}>
							{subcategories.map(({ title, link }) => {
								return (
									<li className={styles.subcategory}>
										<a href={`${process.env.REACT_APP_DOMAIN_NAME}/${link}`}>{title}</a>
									</li>
								)
							})}
						</ul>
					</div>
				)
			})}
			<div className={styles.buttonWrapper}>
				<Button to={`${process.env.REACT_APP_DOMAIN_NAME}/apply`}>Apply</Button>
			</div>
		</nav>
	)
}

import React, { Component } from 'react'
import classes from './TextInput.module.scss'
import PropTypes from 'prop-types'
import { InputError } from '@Root/HOCs'
import { delay } from 'redux-saga'
import { all } from '@Root/helpers'
import regularExpressions from '../../helpers/regularExpressions'

class TextInput extends Component {
	state = {
		error: null,
	}

	showError = async (error) => {
		this.setState({ error })
		await delay(3000)
		this.setState({ error: null })
	}

	handleChange = (value) => {
		const { restriction, changeHandler } = this.props
		if (restriction) {
			const valueIsAllowed = (value, regularExpression) => {
				return value.match(regularExpression)
			}
			;(value === '' || valueIsAllowed(value, regularExpressions[restriction])) && changeHandler(value)
		} else {
			changeHandler(value)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.error !== prevProps.error) {
			this.props.error && this.showError(this.props.error)
		}
	}

	render() {
		const {} = this.state
		const { value, blurHandler = () => {}, isDisabled, placeholder, classNames, style, error, width, maxlength, id } = this.props
		const { handleChange } = this

		return (
			<InputError style={width ? { width } : null} error={error}>
				<input
					className={`${classes.TextInput} ${classNames ? classNames.reduce((acc, className) => acc + ` ${classes[className]}`, '') : ''}`}
					style={style}
					type='text'
					value={value}
					id={id}
					onChange={(e) =>
						all(
							() => handleChange(e.target.value),
							() => this.setState({ error: null })
						)
					}
					onBlur={() => blurHandler()}
					disabled={isDisabled}
					placeholder={placeholder}
					maxLength={maxlength}
				/>
			</InputError>
		)
	}
}

TextInput.propTypes = {
	value: PropTypes.string,
	changeHandler: PropTypes.func,
	blurHandler: PropTypes.func,
	isDisabled: PropTypes.bool,
	placeholder: PropTypes.string,
	classNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent', 'borderlessWhenDisabled'])),
	style: PropTypes.object,
	restriction: PropTypes.oneOf(['letters', 'lettersAndWhiteSpaces', 'digits', 'email']),
	width: PropTypes.string,
	maxlength: PropTypes.number,
}

TextInput.defaultProps = {
	maxLength: Infinity,
}

export default TextInput

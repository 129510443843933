import React, { PureComponent } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { delay } from 'redux-saga'
import { all } from '@Root/helpers'
import onClickOutside from 'react-onclickoutside'
import xIcon from '../../assets/icons/cross.png'

import { CustomScrollbar, InputError } from '@Root/HOCs'
import { popupHeight } from '@Root/helpers'
import ArrowIcon from '../../assets/icons/triangle.png'

class List extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			optionsAreShown: false,
			error: null,
			slicesQuantity: 1,
			maxSlicesQuantity: this.calculateMaxSlicesQuantity(),
		}
	}

	handleScroll = ({ scrollHeight, clientHeight, scrollTop }) => {
		const { slicesQuantity, maxSlicesQuantity } = this.state
		if (scrollTop > scrollHeight - clientHeight - 150) {
			if (slicesQuantity < maxSlicesQuantity) {
				this.setState(({ slicesQuantity }) => ({
					slicesQuantity: slicesQuantity + 1,
				}))
			}
		}
	}

	handleOptionClick = (option) => {
		this.props.changeHandler(option)
		this.setState({ optionsAreShown: false, slicesQuantity: 1 })
	}

	handleClickOutside = () => {
		this.setState({ optionsAreShown: false, slicesQuantity: 1 })
	}

	showError = async (error) => {
		this.setState({ error })
		await delay(3000)
		this.setState({ error: null })
	}

	calculateMaxSlicesQuantity = () => {
		const { options, optionsSliceQuantity } = this.props
		return Math.ceil(options.length / optionsSliceQuantity)
	}

	componentDidUpdate(prevProps) {
		if (this.props.error !== prevProps.error) {
			this.props.error && this.showError(this.props.error)
		}
		if (this.props.options.length !== prevProps.options.length) {
			this.setState({ maxSlicesQuantity: this.calculateMaxSlicesQuantity() })
		}
	}

	render() {
		const { optionsAreShown, error, slicesQuantity } = this.state
		const {
			options: passedOptions,
			value,
			isDisabled,
			style,
			inputClassNames,
			inputStyle,
			optionsStyle,
			placeholder,
			maxVisibleOptionsQuantity,
			changeHandler,
			optionsSliceQuantity,
			hideCross,
		} = this.props
		const { handleScroll, handleOptionClick } = this

		const initialOptions = passedOptions
			.map((passedOption) => (typeof passedOption === 'object' ? passedOption : { label: passedOption, value: passedOption }))
			.filter((option) => option !== null)
		const selectedOption = initialOptions.find((option) => option.value === value)
		const selectedLabel = selectedOption ? selectedOption.label : null
		const options = initialOptions.slice(0, optionsSliceQuantity * slicesQuantity)

		return (
			<div className={classes.Select} style={style}>
				<InputError error={error}>
					<div
						data-test-id={'select'}
						className={`${classes.input} ${isDisabled ? classes.disabled : null}
                        ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, '')}`}
						style={inputStyle}
						onClick={() =>
							all(
								() => !isDisabled && this.setState({ optionsAreShown: !optionsAreShown }),
								() => this.setState({ error: null })
							)
						}
					>
						{value !== null ? (
							<div className={classes.value} data-test-id={'select-value'}>
								{selectedLabel}
							</div>
						) : (
							<div className={`${classes.value} ${classes.empty}`}>{placeholder}</div>
						)}
						{!isDisabled && (
							<>
								{value !== null && !hideCross && (
									<div
										className={classes.xIcon}
										onClick={(event) =>
											all(
												() => event.stopPropagation(),
												() => changeHandler(null)
											)
										}
									>
										<img src={xIcon} alt='' />
									</div>
								)}
								<div className={classes.icon}>
									<img style={optionsAreShown ? { transform: 'rotate(180deg)' } : null} src={ArrowIcon} alt='' />
								</div>
							</>
						)}
					</div>
				</InputError>
				{optionsAreShown && (
					<div
						className={classes.options}
						style={{ ...optionsStyle, height: !!options.length ? popupHeight(options.length, maxVisibleOptionsQuantity, 38) : 38 }}
					>
						<CustomScrollbar verticalOnly scrollHandler={(scrollOptions) => handleScroll(scrollOptions)}>
							{options.length ? (
								options.map((option, i) => (
									<div className={classes.option} key={i}>
										<CustomScrollbar horizontalOnly>
											<div className={classes.text} onClick={() => handleOptionClick(option.value)} data-test-id={i === 0 ? 'test-select-item' : 'other'}>
												{option.label}
											</div>
										</CustomScrollbar>
									</div>
								))
							) : (
								<div className={classes.error}>No options</div>
							)}
						</CustomScrollbar>
					</div>
				)}
			</div>
		)
	}
}

List.propTypes = {
	style: PropTypes.object,
	inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent'])),
	inputStyle: PropTypes.object,
	optionsStyle: PropTypes.object,
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.shape({
				label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
			}),
		])
	),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	changeHandler: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
	placeholder: PropTypes.string,
	maxVisibleOptionsQuantity: PropTypes.number,
	optionsSliceQuantity: PropTypes.number,
	error: PropTypes.string,
}

List.defaultProps = {
	style: {},
	inputClassNames: [],
	inputStyle: {},
	optionsStyle: {},
	options: [],
	value: null,
	changeHandler: () => {},
	isDisabled: false,
	placeholder: '',
	maxVisibleOptionsQuantity: 5,
	optionsSliceQuantity: 50,
	error: null,
}

export const Select = onClickOutside(List)

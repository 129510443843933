import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { InputLabel } from '@Root/Components'

export const References = ({ onChange, data, errors = {}, isMobile }) => {
	const {
		references_1_name,
		references_1_relationship_to_you,
		references_1_email,
		references_2_description,
		references_2_name,
		references_2_relationship_to_you,
		references_2_email,
		additional_info,
	} = data
	return (
		<div className={styles.section}>
			<SectionTitle id={'references'}>9. References</SectionTitle>
			<div className={styles.wrapper}>
				<div className={styles.fields}>
					<InputLabel text='Referee 1 – ministerial reference' />
					<Field
						label={'Name'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '250px' }}
						value={references_1_name}
						onChange={onChange('references_1_name')}
						maxLength={100}
						fieldClass={'long'}
					/>
					<Field
						label={'Relationship to you'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '250px' }}
						value={references_1_relationship_to_you}
						onChange={onChange('references_1_relationship_to_you')}
						maxLength={100}
						fieldClass={'long'}
					/>{' '}
					<div id={!errors.references_1_email ? 'scrollHere' : null}>
						<Field
							label={'Email'}
							field={'textField'}
							//Note: 20px - right and left padding of the field
							style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '250px' }}
							value={references_1_email}
							onChange={onChange('references_1_email')}
							maxLength={100}
							fieldClass={'long'}
							error={errors.references_1_email}
							type={'email'}
							id={'email'}
						/>
					</div>
				</div>
				<div className={styles.fields}>
					<InputLabel text='Referee 2 – academic referee' />
					<Field
						label={'Name'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '250px' }}
						value={references_2_name}
						onChange={onChange('references_2_name')}
						maxLength={100}
						fieldClass={'long'}
					/>
					<Field
						label={'Relationship to you'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '250px' }}
						value={references_2_relationship_to_you}
						onChange={onChange('references_2_relationship_to_you')}
						maxLength={100}
						fieldClass={'long'}
					/>{' '}
					<div id={!errors.references_2_email ? 'scrollHere' : null}>
						<Field
							label={'Email'}
							field={'textField'}
							//Note: 20px - right and left padding of the field
							style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '250px' }}
							value={references_2_email}
							onChange={onChange('references_2_email')}
							maxLength={100}
							fieldClass={'long'}
							error={errors.references_2_email}
							id={'email'}
						/>
					</div>
				</div>
			</div>
			<p>
				{' '}
				* If you have been out of the education system for longer than 10 years it may be more relevant to give the name of a recent employer or someone who can
				tell us about your suitability for study. Use the box below to explain why you have chosen that person.
			</p>
			<Field
				hideLabel
				field={'textArea'}
				style={{ color: '#9B9B9B', fontWeight: 'bold', width: 'calc(100% - 20px)', height: 90 }}
				value={references_2_description}
				onChange={onChange('references_2_description')}
				maxLength={100}
				fieldClass={'single'}
				error={errors.references_2_description}
			/>
			<div className={styles.fields}>
				<Field
					label={'Is there anything else you would like to add to your application?'}
					field={'textField'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '546px' }}
					value={additional_info}
					onChange={onChange('additional_info')}
					maxLength={255}
					fieldClass={'single'}
				/>
			</div>
		</div>
	)
}
